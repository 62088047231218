import { PureComponent, Fragment, FunctionComponent } from 'react';
import moment from 'moment-timezone';
import Layout from '@shares/layout';
import SEO from '@shares/seo';
import { WEB_LEGACY_LINK } from '@config';
import { PageSection } from '@utils';
import Context from '@ui/components/context';
import './style.scss';
import { Props } from './interface';

interface ThankYouProps {
  isListYourCarPage: boolean;
}

export const ThankYou: FunctionComponent<ThankYouProps> = ({ isListYourCarPage }) => {
  const requestedHour = () => {
    const BKKBookingTime = moment().tz('Asia/Bangkok');
    return parseInt(BKKBookingTime.format('H'));
  };

  const contactTime = () => {
    if (requestedHour() >= 20 && requestedHour() <= 24) return 'ภายในวันถัดไป ในเวลาทำการ 08.00 - 20.00 น.';
    if (requestedHour() >= 0 && requestedHour() <= 8) return 'ภายในเวลาทำการ 08.00 - 20.00 น.';
    return 'ภายใน 30 นาที';
  };

  return (
    <div className="thankyou-container">
      <i className="flaticon-checked-circle" />
      <h2>ข้อมูลของท่านถูกส่งเรียบร้อยแล้ว!</h2>
      <p className="thankyou-container__text">
        {isListYourCarPage ? (
          <Fragment>
            เจ้าหน้าที่จะทำการติดต่อกลับภายใน 3-5 วันทำการ
            <br />
            ** ในกรณีที่บริษัท/ร้านของท่าน มีรถเช่ามากกว่า 5 คันขึ้นไป
          </Fragment>
        ) : (
          <Fragment>
            เจ้าหน้าที่จะทำการติดต่อกลับ<strong>{contactTime()}</strong>
          </Fragment>
        )}
      </p>
      <div className="thankyou-container__contact">
        <p>หรือ</p>
        <p style={{ color: '#0078ff' }} className="pb-3">
          <strong>ท่านสามารถสอบถามข้อมูลเพิ่มเติมเกี่ยวกับรถว่าง และราคาได้ที่</strong>
        </p>
        <p>
          Line@ :{' '}
          <a
            rel="nofollow"
            href={`${isListYourCarPage ? 'https://line.me/R/ti/p/%40drivehub.support' : 'https://lin.ee/axOqULu'}`}
          >
            {isListYourCarPage ? '@drivehub.support' : '@drivehub'}
          </a>
        </p>
        <p>
          หรือ Call Centre โทร.{'  '}
          <a rel="noreferrer" href="tel:+6620385222">
            02-038-5222
          </a>
        </p>
        <div className="contact-img">
          <a
            rel="nofollow"
            href={`${isListYourCarPage ? 'https://line.me/R/ti/p/%40drivehub.support' : 'https://lin.ee/axOqULu'}`}
          >
            <img
              src={`/assets/shares/${isListYourCarPage ? 'line-qr-code.jpg' : 'line-qr-code-cs.png'}`}
              alt="qrcode"
              id="line-qrcode"
            />
            <img src="/assets/shares/line-add-friend.png" alt="line-add" id="line-add" />
          </a>
        </div>
      </div>
    </div>
  );
};

class LongTermRentalSuccessfully extends PureComponent<Props> {
  render() {
    const { prefix } = this.props.pageContext;
    return (
      <Context>
        <Layout section={PageSection.LongTermRental} prefix={prefix} isTransparent={false}>
          <SEO
            title="ข้อมูลของคุณถูกส่งเรียบร้อยแล้ว - เช่ารถระยะยาว"
            prefix={prefix}
            path="long-term-rental/successfully"
            canonical={`${WEB_LEGACY_LINK}/${prefix}/long-term-rental/successfully`}
            description="Drivehub เช่ารถระะยาว เปิดบริการแล้ว รับราคาพิเศษ ง่ายๆ เพียงติดต่อเรา!"
            keywords="เช่ารถ, รถเช่า, ระยะยาว, 7วัน, 15 วัน"
          />
          <div className="long-term-rental-successfully">
            <div className="container">
              <ThankYou isListYourCarPage={false} />
            </div>
          </div>
        </Layout>
      </Context>
    );
  }
}

export default LongTermRentalSuccessfully;
